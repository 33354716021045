import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";
import { isEqual } from "lodash";

import Input from "components/input";
import Select from "components/select";
import Button from "components/button";
import BadgeCelcoin from "../../common/badge-celcoin";
import { tipoEmpresaArr, naturezaLegalArr } from "utils/tipoEmpresa";
import { setEditingForm } from "reducers/client/clientSlice";
import { updateClient } from "reducers/client/clientThunk";
import { banks } from "utils/banks";
import {
  cnpjMask,
  phoneMask,
  dateMask,
  numberMask,
  currencyMask,
  convertCurrencyBRLToFloat,
} from "utils/mask";
import { checkRole } from "utils/isRoleAllowed";
import { USER_ADMIN, USER_OPERACOES, ADMIN_ECOSYSTEM } from "utils/const";

export default function CompanyData() {
  const { client, isUpdating, editingForm } = useSelector(
    (store) => store.client
  );
  const { role } = useSelector((store) => store.user);
  const [companyData, setCompanyData] = useState({});
  const [formErrors, setFormErrors] = useState({});
  const [formattedValue, setFormattedValue] = useState("");
  const dispatch = useDispatch();
  const [logo, setLogo] = useState();
  const [isEditingMode, setIsEditingMode] = useState(false);

  useEffect(() => {
    !isUpdating && setIsEditingMode(false);
  }, [isUpdating]);

  useEffect(() => {
    banks.forEach((bank) => {
      if (bank.idCustomerBank === client.idCustomerBank) {
        setLogo(bank.svg);
      }
    });
  }, [client]);

  useEffect(() => {
    setCompanyData({
      razaoSocial: client.razaoSocial,
      nomeFantasia: client.nomeFantasia,
      inscricaoEstadual: client.inscricaoEstadual,
      email: client.email,
      numeroTelefone: `${client.codigoArea}${client.numeroTelefone}`,
      dataAbertura: moment(client.dataAbertura).format("DD/MM/YYYY"),
      cnae: client.cnae,
      naturezaLegal:
        client.naturezaLegal &&
        client.naturezaLegal.replace(/[^0-9-]/g, "").replace(/-([^]*)-/, "-$1"),
      tipoEmpresa: client.tipoEmpresa,
      faturamento: client.faturamento,
    });

    if (client.faturamento) {
      const currencyFractionDigits = new Intl.NumberFormat("pt-br", {
        style: "currency",
        currency: "BRL",
      }).resolvedOptions().maximumFractionDigits;

      const value = client.faturamento.toLocaleString("pt-br", {
        maximumFractionDigits: currencyFractionDigits,
        minimumFractionDigits: 2,
      });

      setFormattedValue(value);
    }
  }, [client, isEditingMode]);

  const changeCompanyData = (event) => {
    const { name, value } = event.target;

    setCompanyData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const validateForm = (values) => {
    const errors = {};
    const emailRegex =
      /^[a-zA-Z0-9.!#$%&"*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;

    if (!values.razaoSocial) {
      errors.razaoSocial = "Razão Social deve ser informada!";
    }

    if (!values.nomeFantasia) {
      errors.nomeFantasia = "Nome Fantasia deve ser informado!";
    }

    if (!values.inscricaoEstadual) {
      errors.inscricaoEstadual = "Inscrição estadual deve ser informada!";
    }

    if (!values.dataAbertura) {
      errors.dataAbertura = "Data de abertura deve ser informada!";
    } else if (values.dataAbertura.length < 10) {
      errors.dataAbertura = "Data de abertura inválida!";
    } else if (!moment(values.dataAbertura, "DD/MM/YYYY").isValid()) {
      errors.dataAbertura = "Data de abertura inválida!";
    }

    if (!values.email) {
      errors.email = "Email deve ser informado!";
    } else if (!emailRegex.test(values.email)) {
      errors.email = "Email em formato inválido!";
    }

    if (!values.numeroTelefone) {
      errors.numeroTelefone = "Celular deve ser informado!";
    } else if (numberMask(values.numeroTelefone).length !== 11) {
      errors.numeroTelefone = "Celular deve ter 11 dígitos!";
    }

    if (!values.tipoEmpresa) {
      errors.tipoEmpresa = "Tipo da empresa deve ser informado!";
    }

    if (!values.naturezaLegal) {
      errors.naturezaLegal = "Natureza legal deve ser informada!";
    }

    if (!values.cnae) {
      errors.cnae = "CNAE deve ser informado!";
    }

    if (!values.faturamento) {
      errors.faturamento = "Faturamento mensal deve ser informado!";
    }

    setFormErrors(errors);

    if (Object.keys(errors).length === 0) {
      return true;
    } else {
      return false;
    }
  };

  const compareCompanyData = () => {
    const clientStore = {
      razaoSocial: client.razaoSocial,
      nomeFantasia: client.nomeFantasia,
      inscricaoEstadual: client.inscricaoEstadual,
      email: client.email,
      numeroTelefone: `${client.codigoArea}${client.numeroTelefone}`,
      dataAbertura: moment(client.dataAbertura).format("DD/MM/YYYY"),
      cnae: client.cnae,
      naturezaLegal: client.naturezaLegal,
      tipoEmpresa: client.tipoEmpresa,
      faturamento: client.faturamento,
    };

    return isEqual(clientStore, {
      ...companyData,
      numeroTelefone: numberMask(companyData.numeroTelefone),
      faturamento: convertCurrencyBRLToFloat(formattedValue),
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    if (
      validateForm({ ...companyData, faturamento: formattedValue }) &&
      !isUpdating
    ) {
      dispatch(
        updateClient({
          ...client,
          ...companyData,
          accountType: 2,
          dataAbertura: companyData.dataAbertura,
          codigoArea: numberMask(companyData.numeroTelefone).substr(0, 2),
          numeroTelefone: numberMask(companyData.numeroTelefone).substr(2),
          faturamento: convertCurrencyBRLToFloat(formattedValue),
        })
      );
      dispatch(setEditingForm(""));
    }
  };

  const handleEdit = () => {
    dispatch(setEditingForm("company-data"));
    setIsEditingMode(true);
  };

  const handleCancel = () => {
    dispatch(setEditingForm(""));
    setIsEditingMode(false);
    setFormErrors({});
  };

  return (
    <form action="#" method="POST" onSubmit={handleSubmit}>
      <div className="shadow sm:overflow-hidden sm:rounded-md">
        <div className="space-y-6 bg-white py-6 px-4 sm:p-6">
          <div className="flex items-center justify-between">
            <div className="flex flex-col justify-start">
              <h3 className="text-lg font-medium leading-6 text-gray-900">
                Dados da empresa
              </h3>
              <BadgeCelcoin celcoinStatus={client.celcoinStatus} />
            </div>
            {logo ? (
              <div className="flex justify-end max-w-[120px] sm:max-w-[130px] h-8">
                {logo}
              </div>
            ) : (
              <p className="text-lg leading-6 font-medium text-gray-900">
                {client.nomeBanco}
              </p>
            )}
          </div>

          <div className="grid grid-cols-6 gap-6">
            <div className="col-span-6 sm:col-span-3">
              <Input
                label={"Razão social"}
                type={"text"}
                name={"razaoSocial"}
                id={"razaoSocial"}
                value={companyData.razaoSocial || ""}
                onChange={changeCompanyData}
                error={formErrors.razaoSocial}
                errorMessage={formErrors.razaoSocial}
                disabled={!isEditingMode}
              />
            </div>
            <div className="col-span-6 sm:col-span-3">
              <Input
                label={"Nome fantasia"}
                type={"text"}
                name={"nomeFantasia"}
                id={"nomeFantasia"}
                value={companyData.nomeFantasia || ""}
                onChange={changeCompanyData}
                error={formErrors.nomeFantasia}
                errorMessage={formErrors.nomeFantasia}
                disabled={!isEditingMode}
              />
            </div>
            <div className="col-span-6 sm:col-span-3">
              <Input
                label={"CNPJ"}
                type={"text"}
                name={"cnpj"}
                id={"cnpj"}
                value={cnpjMask(client.cnpj) || ""}
                onChange={() => {}}
                disabled
              />
            </div>
            <div className="col-span-6 sm:col-span-3">
              <Input
                label={"Inscrição estadual"}
                type={"text"}
                name={"inscricaoEstadual"}
                id={"inscricaoEstadual"}
                value={companyData.inscricaoEstadual || ""}
                onChange={changeCompanyData}
                error={formErrors.inscricaoEstadual}
                errorMessage={formErrors.inscricaoEstadual}
                disabled={!isEditingMode}
              />
            </div>
            <div className="col-span-6 sm:col-span-3">
              <Input
                label={"Email"}
                type={"text"}
                name={"email"}
                id={"email"}
                value={companyData.email || ""}
                onChange={changeCompanyData}
                error={formErrors.email}
                errorMessage={formErrors.email}
                disabled={!isEditingMode}
              />
            </div>
            <div className="col-span-6 sm:col-span-3">
              <Input
                label={"Celular"}
                type={"text"}
                name={"numeroTelefone"}
                id={"numeroTelefone"}
                maxLength={15}
                value={phoneMask(companyData.numeroTelefone) || ""}
                onChange={changeCompanyData}
                error={formErrors.numeroTelefone}
                errorMessage={formErrors.numeroTelefone}
                disabled={!isEditingMode}
              />
            </div>
            <div className="col-span-6 sm:col-span-3">
              <Input
                label={"Data de abertura"}
                type={"text"}
                name={"dataAbertura"}
                id={"dataAbertura"}
                value={dateMask(companyData.dataAbertura) || ""}
                onChange={changeCompanyData}
                error={formErrors.dataAbertura}
                errorMessage={formErrors.dataAbertura}
                disabled={!isEditingMode}
              />
            </div>
            <div className="col-span-6 sm:col-span-3">
              <Input
                label={"CNAE"}
                type={"text"}
                name={"cnae"}
                id={"cnae"}
                value={companyData.cnae || ""}
                onChange={changeCompanyData}
                error={formErrors.cnae}
                errorMessage={formErrors.cnae}
                disabled={!isEditingMode}
              />
            </div>
            <div className="col-span-6 sm:col-span-3">
              <Select
                label={"Natureza legal"}
                id={"naturezaLegal"}
                name={"naturezaLegal"}
                value={companyData.naturezaLegal || ""}
                placeholder={"Selecione a natureza legal"}
                options={naturezaLegalArr}
                onChange={changeCompanyData}
                error={formErrors.naturezaLegal}
                errorMessage={formErrors.naturezaLegal}
                disabled={!isEditingMode}
              />
            </div>
            <div className="col-span-6 sm:col-span-3">
              <Select
                label={"Tipo de empresa"}
                id={"tipoEmpresa"}
                name={"tipoEmpresa"}
                value={companyData.tipoEmpresa || ""}
                placeholder={"Selecione o tipo da empresa"}
                options={tipoEmpresaArr}
                onChange={changeCompanyData}
                error={formErrors.tipoEmpresa}
                errorMessage={formErrors.tipoEmpresa}
                disabled={!isEditingMode}
              />
            </div>
            <div className="col-span-6 sm:col-span-3">
              <Input
                label={"Faturamento mensal"}
                type={"text"}
                name={"faturamento"}
                id={"faturamento"}
                prefix={"R$"}
                value={formattedValue || ""}
                placeholder={"0,00"}
                onChange={(e) =>
                  setFormattedValue(currencyMask(e.target.value))
                }
                error={formErrors.faturamento}
                errorMessage={formErrors.faturamento}
                disabled={!isEditingMode}
              />
            </div>
          </div>
        </div>
        {checkRole(role, [USER_ADMIN, USER_OPERACOES, ADMIN_ECOSYSTEM]) && (
          <div className="bg-gray-50 px-4 py-3 space-x-3 text-right sm:px-6">
            {!isEditingMode && (
              <Button
                type={"button"}
                label={"Editar"}
                onClick={handleEdit}
                disabled={editingForm && editingForm !== "company-data"}
              />
            )}
            {isEditingMode && (
              <>
                <Button
                  type={"submit"}
                  label={"Salvar"}
                  onClick={handleSubmit}
                  disabled={compareCompanyData()}
                />
                <Button
                  type={"button"}
                  secondary
                  label={"Cancelar"}
                  onClick={handleCancel}
                />
              </>
            )}
          </div>
        )}
      </div>
    </form>
  );
}
