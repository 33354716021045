import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { v4 as uuidv4 } from "uuid";
import moment from "moment";

import Input from "components/input";
import Button from "components/button";
import Select from "components/select";
import ConfirmationModal from "components/modal-confirmation";
import TextArea from "components/textarea";
import CheckBox from "components/checkbox";
import { ufs } from "utils/ufs";
import { fetchZipcode } from "reducers/zipcode/zipcodeThunk";
import { postPjAccount } from "reducers/onboarding/onboardingThunk";
import { isResponseSuccess } from "utils/request";
import { tipoEmpresaArr, naturezaLegalArr } from "utils/tipoEmpresa";
import {
  cnpjMask,
  cpfMask,
  phoneMask,
  numberMask,
  cepMask,
  dateMask,
  currencyMask,
  convertCurrencyBRLToFloat,
} from "utils/mask";

export default function NewAccountPj() {
  const [formData, setFormData] = useState({});
  const [formErrors, setFormErrors] = useState({});
  const [formattedValue, setFormattedValue] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { isPosting } = useSelector((store) => store.onboarding);
  const { id_Customer_Bank } = useSelector((store) => store.user);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  function handleChange(event) {
    const { name, value, type, checked } = event.target;

    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: type === "checkbox" ? checked : value,
    }));
  }

  async function handleBlur(event) {
    const { name, value } = event.target;
    const formZipcode = numberMask(value);

    if (formZipcode.length === 8) {
      const response = await dispatch(fetchZipcode(formZipcode));

      if (isResponseSuccess(response)) {
        if (name === "enderecoCEP") {
          setFormData((prevFormData) => ({
            ...prevFormData,
            endereco: response.payload.logradouro,
            enderecoBairro: response.payload.bairro,
            enderecoCidade: response.payload.localidade,
            enderecoEstado: response.payload.uf,
            enderecoCEP: response.payload.cep,
          }));
        } else if (name === "enderecoCEPSocio") {
          setFormData((prevFormData) => ({
            ...prevFormData,
            enderecoSocio: response.payload.logradouro,
            enderecoBairroSocio: response.payload.bairro,
            enderecoCidadeSocio: response.payload.localidade,
            enderecoEstadoSocio: response.payload.uf,
            enderecoCEPSocio: response.payload.cep,
          }));
        }
      }
    }
  }

  async function handleSubmit() {
    if (!isPosting) {
      const response = await dispatch(
        postPjAccount({
          ...formData,
          username: numberMask(formData.cnpj),
          senha: uuidv4(),
          idCustomerBank: parseInt(id_Customer_Bank),
          cnpj: numberMask(formData.cnpj),
          dataAbertura: moment(formData.dataAbertura, "DD/MM/YYYY").format(
            "YYYY-MM-DD"
          ),
          codigoArea: numberMask(formData.numeroTelefone).substr(0, 2),
          numeroTelefone: numberMask(formData.numeroTelefone).substr(2),
          faturamento: convertCurrencyBRLToFloat(formattedValue),
          enderecoCEP: numberMask(formData.enderecoCEP),
          dataNascimentoSocio: moment(
            formData.dataNascimentoSocio,
            "DD/MM/YYYY"
          ).format("YYYY-MM-DD"),
          cpfSocio: numberMask(formData.cpfSocio),
          codigoAreaSocio: numberMask(formData.numeroTelefoneSocio).substr(
            0,
            2
          ),
          numeroTelefoneSocio: numberMask(formData.numeroTelefoneSocio).substr(
            2
          ),
          dataEmissaoRG: moment(formData.dataEmissaoRG, "DD/MM/YYYY").format(
            "YYYY-MM-DD"
          ),
          enderecoCEPSocio: numberMask(formData.enderecoCEPSocio),
          pepSocio:
            !formData.pepSocio || formData.pepSocio === "1" ? false : true,
          dataInicioPEPSocio: formData.dataInicioPEPSocio
            ? moment(formData.dataInicioPEPSocio, "DD/MM/YYYY").format(
                "YYYY-MM-DD"
              )
            : "1900-01-01",
        })
      );

      if (isResponseSuccess(response)) {
        navigate("/contas");
      }
    }
  }

  function validateForm(values) {
    const errors = {};
    const emailRegex =
      /^[a-zA-Z0-9.!#$%&"*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;

    if (!values.razaoSocial) {
      errors.razaoSocial = "Razão Social deve ser informada!";
    }

    if (!values.nomeFantasia) {
      errors.nomeFantasia = "Nome Fantasia deve ser informado!";
    }

    if (!values.cnpj) {
      errors.cnpj = "CNPJ deve ser informado!";
    } else if (numberMask(values.cnpj).length < 14) {
      errors.cnpj = "CNPJ deve ter 14 dígitos!";
    }

    if (!values.inscricaoEstadual) {
      errors.inscricaoEstadual = "Inscrição estadual deve ser informada!";
    }

    if (!values.dataAbertura) {
      errors.dataAbertura = "Data de abertura deve ser informada!";
    } else if (values.dataAbertura.length < 10) {
      errors.dataAbertura = "Data de abertura inválida!";
    } else if (!moment(values.dataAbertura, "DD/MM/YYYY").isValid()) {
      errors.dataAbertura = "Data de abertura inválida!";
    }

    if (!values.email) {
      errors.email = "Email deve ser informado!";
    } else if (!emailRegex.test(values.email)) {
      errors.email = "Email em formato inválido!";
    }

    if (!values.numeroTelefone) {
      errors.numeroTelefone = "Celular deve ser informado!";
    } else if (numberMask(values.numeroTelefone).length !== 11) {
      errors.numeroTelefone = "Celular deve ter 11 dígitos!";
    }

    if (!values.tipoEmpresa) {
      errors.tipoEmpresa = "Tipo da empresa deve ser informado!";
    }

    if (!values.naturezaLegal) {
      errors.naturezaLegal = "Natureza legal deve ser informada!";
    }

    if (!values.cnae) {
      errors.cnae = "CNAE deve ser informado!";
    }

    if (!values.nomeSocio) {
      errors.nomeSocio = "Nome do sócio deve ser informado!";
    }

    if (!values.cpfSocio) {
      errors.cpfSocio = "CPF deve ser informado!";
    } else if (numberMask(values.cpfSocio).length < 11) {
      errors.cpfSocio = "CPF deve ter 11 dígitos!";
    }

    if (!values.dataNascimentoSocio) {
      errors.dataNascimentoSocio = "Data de nascimento deve ser informada!";
    } else if (values.dataNascimentoSocio.length < 10) {
      errors.dataNascimentoSocio = "Data de nascimento inválida!";
    } else if (!moment(values.dataNascimentoSocio, "DD/MM/YYYY").isValid()) {
      errors.dataNascimentoSocio = "Data de nascimento inválida!";
    }

    if (!values.numeroTelefoneSocio) {
      errors.numeroTelefoneSocio = "Celular deve ser informado!";
    } else if (numberMask(values.numeroTelefoneSocio).length !== 11) {
      errors.numeroTelefoneSocio = "Celular deve ter 11 dígitos!";
    }

    if (!values.nacionalidade) {
      errors.nacionalidade = "Nacionalidade deve ser informada!";
    }

    if (!values.nomeMaeSocio) {
      errors.nomeMaeSocio = "Nome da mãe deve ser informado!";
    }

    if (!values.rgSocio) {
      errors.rgSocio = "RG deve ser informado!";
    }

    if (!values.estadoRGSocio) {
      errors.estadoRGSocio = "Estado de emissão do RG deve ser informado!";
    }

    if (!values.emissorRGSocio) {
      errors.emissorRGSocio = "Orgão emissor do RG deve ser informado!";
    }

    if (!values.dataEmissaoRG) {
      errors.dataEmissaoRG = "Data de emissão do RG deve ser informada!";
    } else if (values.dataEmissaoRG.length < 10) {
      errors.dataEmissaoRG = "Data inválida!";
    } else if (!moment(values.dataEmissaoRG, "DD/MM/YYYY").isValid()) {
      errors.dataEmissaoRG = "Data inválida!";
    }

    if (!values.nacionalidade) {
      errors.nacionalidade = "Nacionalidade deve ser informada!";
    }

    if (!values.pepSocio) {
      errors.pepSocio = "Pessoa Exposta Politicamente deve ser informada!";
    }

    if (values.pepSocio === "2") {
      if (!values.dataInicioPEPSocio) {
        errors.dataInicioPEPSocio = "Data de início deve ser informada!";
      } else if (values.dataInicioPEPSocio.length < 10) {
        errors.dataInicioPEPSocio = "Data de início inválida!";
      } else if (!moment(values.dataInicioPEPSocio, "DD/MM/YYYY").isValid()) {
        errors.dataInicioPEPSocio = "Data de início inválida!";
      }
    }

    if (!values.enderecoCEP) {
      errors.enderecoCEP = "CEP deve ser informado!";
    } else if (numberMask(values.enderecoCEP).length !== 8) {
      errors.enderecoCEP = "CEP deve ter 8 dígitos!";
    }

    if (!values.endereco) {
      errors.endereco = "Rua deve ser informada!";
    }

    if (!values.enderecoNumero) {
      errors.enderecoNumero = "Número deve ser informado!";
    }

    if (!values.enderecoBairro) {
      errors.enderecoBairro = "Bairro deve ser informado!";
    }

    if (!values.enderecoEstado) {
      errors.enderecoEstado = "Estado deve ser informado!";
    }

    if (!values.enderecoCidade) {
      errors.enderecoCidade = "Cidade deve ser informada!";
    }

    if (!values.enderecoCEPSocio) {
      errors.enderecoCEPSocio = "CEP deve ser informado!";
    } else if (numberMask(values.enderecoCEPSocio).length !== 8) {
      errors.enderecoCEPSocio = "CEP deve ter 8 dígitos!";
    }

    if (!values.enderecoSocio) {
      errors.enderecoSocio = "Rua deve ser informada!";
    }

    if (!values.enderecoNumeroSocio) {
      errors.enderecoNumeroSocio = "Número deve ser informado!";
    }

    if (!values.enderecoBairroSocio) {
      errors.enderecoBairroSocio = "Bairro deve ser informado!";
    }

    if (!values.enderecoEstadoSocio) {
      errors.enderecoEstadoSocio = "Estado deve ser informado!";
    }

    if (!values.enderecoCidadeSocio) {
      errors.enderecoCidadeSocio = "Cidade deve ser informada!";
    }

    if (!values.solicitanteRazaoOnboarding) {
      errors.solicitanteRazaoOnboarding =
        "Motivo de abertura manual deve ser informado!";
    }

    if (!values.faturamento) {
      errors.faturamento = "Faturamento mensal deve ser informado!";
    }

    setFormErrors(errors);

    if (Object.keys(errors).length === 0) {
      return true;
    } else {
      return false;
    }
  }

  return (
    <>
      <ConfirmationModal
        title={"Deseja prosseguir?"}
        description={"Caso confirme, sua ação não poderá ser desfeita."}
        confirmButtonLabel={"Continuar"}
        onConfirm={handleSubmit}
        cancelButtonLabel={"Cancelar"}
        onCancel={() => setIsModalOpen(false)}
        isModalOpen={isModalOpen}
      />
      <form
        className="space-y-6"
        onSubmit={(e) => {
          e.preventDefault();
          validateForm({ ...formData, faturamento: formattedValue }) &&
            setIsModalOpen(true);
        }}
        action="#"
        method="POST"
      >
        <div className="shadow overflow-hidden sm:rounded-md">
          <div className="px-6 py-4 sm:py-5 bg-white border-b border-gray-200">
            <h3 className="text-lg font-medium leading-6 text-gray-900">
              Dados da empresa
            </h3>
          </div>
          <div className="px-4 py-7 bg-white sm:p-7">
            <div className="grid grid-cols-6 gap-7">
              <div className="col-span-6 sm:col-span-3">
                <Input
                  label={"Razão social"}
                  type={"text"}
                  name={"razaoSocial"}
                  id={"razaoSocial"}
                  value={formData.razaoSocial || ""}
                  error={formErrors.razaoSocial}
                  errorMessage={formErrors.razaoSocial}
                  onChange={handleChange}
                />
              </div>
              <div className="col-span-6 sm:col-span-3">
                <Input
                  label={"Nome fantasia"}
                  type={"text"}
                  name={"nomeFantasia"}
                  id={"nomeFantasia"}
                  value={formData.nomeFantasia || ""}
                  error={formErrors.nomeFantasia}
                  errorMessage={formErrors.nomeFantasia}
                  onChange={handleChange}
                />
              </div>
              <div className="col-span-6 sm:col-span-3">
                <Input
                  label={"CNPJ"}
                  type={"text"}
                  name={"cnpj"}
                  id={"cnpj"}
                  value={cnpjMask(formData.cnpj) || ""}
                  error={formErrors.cnpj}
                  errorMessage={formErrors.cnpj}
                  onChange={handleChange}
                />
              </div>
              <div className="col-span-6 sm:col-span-3">
                <Input
                  label={"Inscrição estadual"}
                  type={"text"}
                  name={"inscricaoEstadual"}
                  id={"inscricaoEstadual"}
                  value={formData.inscricaoEstadual || ""}
                  error={formErrors.inscricaoEstadual}
                  errorMessage={formErrors.inscricaoEstadual}
                  onChange={handleChange}
                />
              </div>
              <div className="col-span-6 sm:col-span-3">
                <Input
                  label={"Data de abertura"}
                  type={"text"}
                  name={"dataAbertura"}
                  id={"dataAbertura"}
                  value={dateMask(formData.dataAbertura) || ""}
                  error={formErrors.dataAbertura}
                  errorMessage={formErrors.dataAbertura}
                  onChange={handleChange}
                  placeholder={"dd/mm/aaaa"}
                />
              </div>
              <div className="col-span-6 sm:col-span-3">
                <Input
                  label={"Email"}
                  type={"text"}
                  name={"email"}
                  id={"email"}
                  value={formData.email || ""}
                  error={formErrors.email}
                  errorMessage={formErrors.email}
                  onChange={handleChange}
                  autoComplete={"off"}
                />
              </div>
              <div className="col-span-6 sm:col-span-3">
                <Input
                  label={"Celular"}
                  type={"text"}
                  name={"numeroTelefone"}
                  id={"numeroTelefone"}
                  maxLength={15}
                  value={phoneMask(formData.numeroTelefone) || ""}
                  error={formErrors.numeroTelefone}
                  errorMessage={formErrors.numeroTelefone}
                  onChange={handleChange}
                />
              </div>
              <div className="col-span-6 sm:col-span-3">
                <Select
                  label={"Tipo de empresa"}
                  id={"tipoEmpresa"}
                  name={"tipoEmpresa"}
                  value={formData.tipoEmpresa || ""}
                  onChange={handleChange}
                  placeholder={"Selecione o tipo da empresa"}
                  options={tipoEmpresaArr}
                  error={formErrors.tipoEmpresa}
                  errorMessage={formErrors.tipoEmpresa}
                />
              </div>
              <div className="col-span-6 sm:col-span-3">
                <Select
                  label={"Natureza legal"}
                  id={"naturezaLegal"}
                  name={"naturezaLegal"}
                  value={formData.naturezaLegal || ""}
                  onChange={handleChange}
                  placeholder={"Selecione a natureza legal"}
                  options={naturezaLegalArr}
                  error={formErrors.naturezaLegal}
                  errorMessage={formErrors.naturezaLegal}
                />
              </div>
              <div className="col-span-6 sm:col-span-3">
                <Input
                  label={"CNAE"}
                  type={"text"}
                  name={"cnae"}
                  id={"cnae"}
                  value={formData.cnae || ""}
                  error={formErrors.cnae}
                  errorMessage={formErrors.cnae}
                  onChange={handleChange}
                />
              </div>
              <div className="col-span-6 sm:col-span-3">
                <Input
                  label={"Faturamento mensal"}
                  type={"text"}
                  name={"faturamento"}
                  id={"faturamento"}
                  prefix={"R$"}
                  value={formattedValue || ""}
                  onChange={(e) =>
                    setFormattedValue(currencyMask(e.target.value))
                  }                  
                  error={formErrors.faturamento}
                  errorMessage={formErrors.faturamento}
                  placeholder={"0,00"}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="shadow overflow-hidden sm:rounded-md">
          <div className="px-6 py-4 sm:py-5 bg-white border-b border-gray-200">
            <h3 className="text-lg font-medium leading-6 text-gray-900">
              Endereço comercial
            </h3>
          </div>
          <div className="px-4 py-7 bg-white sm:p-7">
            <div className="grid grid-cols-6 gap-7">
              <div className="col-span-6 sm:col-span-2 lg:col-span-3">
                <Input
                  label={"CEP"}
                  type={"text"}
                  name={"enderecoCEP"}
                  id={"enderecoCEP"}
                  value={cepMask(formData.enderecoCEP) || ""}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={formErrors.enderecoCEP}
                  errorMessage={formErrors.enderecoCEP}
                />
              </div>
              <div className="col-span-6">
                <Input
                  label={"Rua"}
                  type={"text"}
                  name={"endereco"}
                  id={"endereco"}
                  value={formData.endereco || ""}
                  onChange={handleChange}
                  error={formErrors.endereco}
                  errorMessage={formErrors.endereco}
                />
              </div>
              <div className="col-span-6 sm:col-span-2">
                <Input
                  label={"Número"}
                  type={"text"}
                  name={"enderecoNumero"}
                  id={"enderecoNumero"}
                  value={formData.enderecoNumero || ""}
                  onChange={handleChange}
                  error={formErrors.enderecoNumero}
                  errorMessage={formErrors.enderecoNumero}
                />
              </div>
              <div className="col-span-6 sm:col-span-2">
                <Input
                  label={"Bairro"}
                  type={"text"}
                  name={"enderecoBairro"}
                  id={"enderecoBairro"}
                  value={formData.enderecoBairro || ""}
                  onChange={handleChange}
                  error={formErrors.enderecoBairro}
                  errorMessage={formErrors.enderecoBairro}
                />
              </div>
              <div className="col-span-6 sm:col-span-2">
                <Input
                  label={"Complemento"}
                  type={"text"}
                  name={"enderecoComplemento"}
                  id={"enderecoComplemento"}
                  value={formData.enderecoComplemento || ""}
                  onChange={handleChange}
                />
              </div>
              <div className="col-span-6 sm:col-span-3">
                <Select
                  label={"Estado"}
                  id={"enderecoEstado"}
                  name={"enderecoEstado"}
                  value={formData.enderecoEstado || ""}
                  onChange={handleChange}
                  placeholder={"Selecione o estado"}
                  options={ufs}
                  error={formErrors.enderecoEstado}
                  errorMessage={formErrors.enderecoEstado}
                />
              </div>
              <div className="col-span-6 sm:col-span-3">
                <Input
                  label={"Cidade"}
                  type={"text"}
                  name={"enderecoCidade"}
                  id={"enderecoCidade"}
                  value={formData.enderecoCidade || ""}
                  onChange={handleChange}
                  error={formErrors.enderecoCidade}
                  errorMessage={formErrors.enderecoCidade}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="shadow overflow-hidden sm:rounded-md">
          <div className="px-6 py-4 sm:py-5 bg-white border-b border-gray-200">
            <h3 className="text-lg font-medium leading-6 text-gray-900">
              Dados do sócio
            </h3>
          </div>
          <div className="px-4 py-7 bg-white sm:p-7">
            <div className="grid grid-cols-6 gap-7">
              <div className="col-span-6 sm:col-span-3">
                <Input
                  label={"Nome"}
                  type={"text"}
                  name={"nomeSocio"}
                  id={"nomeSocio"}
                  value={formData.nomeSocio || ""}
                  error={formErrors.nomeSocio}
                  errorMessage={formErrors.nomeSocio}
                  onChange={handleChange}
                />
              </div>
              <div className="col-span-6 sm:col-span-3">
                <Input
                  label={"CPF"}
                  type={"text"}
                  name={"cpfSocio"}
                  id={"cpfSocio"}
                  value={cpfMask(formData.cpfSocio) || ""}
                  error={formErrors.cpfSocio}
                  errorMessage={formErrors.cpfSocio}
                  onChange={handleChange}
                />
              </div>
              <div className="col-span-6 sm:col-span-3">
                <Input
                  label={"Data de nascimento"}
                  type={"text"}
                  name={"dataNascimentoSocio"}
                  id={"dataNascimentoSocio"}
                  value={dateMask(formData.dataNascimentoSocio) || ""}
                  error={formErrors.dataNascimentoSocio}
                  errorMessage={formErrors.dataNascimentoSocio}
                  onChange={handleChange}
                  placeholder={"dd/mm/aaaa"}
                />
              </div>
              <div className="col-span-6 sm:col-span-3">
                <Input
                  label={"Telefone"}
                  type={"text"}
                  name={"numeroTelefoneSocio"}
                  id={"numeroTelefoneSocio"}
                  maxLength={15}
                  value={phoneMask(formData.numeroTelefoneSocio) || ""}
                  error={formErrors.numeroTelefoneSocio}
                  errorMessage={formErrors.numeroTelefoneSocio}
                  onChange={handleChange}
                />
              </div>
              <div className="col-span-6 sm:col-span-3">
                <Input
                  label={"RG"}
                  type={"text"}
                  name={"rgSocio"}
                  id={"rgSocio"}
                  value={formData.rgSocio || ""}
                  error={formErrors.rgSocio}
                  errorMessage={formErrors.rgSocio}
                  onChange={handleChange}
                />
              </div>
              <div className="col-span-6 sm:col-span-3">
                <Select
                  label={"Estado de emissão RG"}
                  id={"estadoRGSocio"}
                  name={"estadoRGSocio"}
                  value={formData.estadoRGSocio || ""}
                  onChange={handleChange}
                  placeholder={"Selecione o estado"}
                  options={ufs}
                  error={formErrors.estadoRGSocio}
                  errorMessage={formErrors.estadoRGSocio}
                />
              </div>
              <div className="col-span-6 sm:col-span-3">
                <Input
                  label={"Orgão emissor do RG"}
                  type={"text"}
                  name={"emissorRGSocio"}
                  id={"emissorRGSocio"}
                  value={formData.emissorRGSocio || ""}
                  error={formErrors.emissorRGSocio}
                  errorMessage={formErrors.emissorRGSocio}
                  onChange={handleChange}
                />
              </div>
              <div className="col-span-6 sm:col-span-3">
                <Input
                  label={"Data de Emissão RG"}
                  type={"text"}
                  name={"dataEmissaoRG"}
                  id={"dataEmissaoRG"}
                  value={dateMask(formData.dataEmissaoRG) || ""}
                  error={formErrors.dataEmissaoRG}
                  errorMessage={formErrors.dataEmissaoRG}
                  onChange={handleChange}
                  placeholder={"dd/mm/aaaa"}
                />
              </div>
              <div className="col-span-6 sm:col-span-3">
                <Input
                  label={"Nacionalidade"}
                  type={"text"}
                  name={"nacionalidade"}
                  id={"nacionalidade"}
                  value={formData.nacionalidade || ""}
                  error={formErrors.nacionalidade}
                  errorMessage={formErrors.nacionalidade}
                  onChange={handleChange}
                />
              </div>
              <div className="col-span-6 sm:col-span-3">
                <Input
                  label={"Nome completo da mãe"}
                  type={"text"}
                  name={"nomeMaeSocio"}
                  id={"nomeMaeSocio"}
                  value={formData.nomeMaeSocio || ""}
                  error={formErrors.nomeMaeSocio}
                  errorMessage={formErrors.nomeMaeSocio}
                  onChange={handleChange}
                />
              </div>
              <div className="col-span-6 sm:col-span-3">
                <Select
                  label={"Pessoa Politicamente Exposta (PPE)"}
                  id={"pepSocio"}
                  name={"pepSocio"}
                  placeholder={"Selecione uma opção"}
                  value={formData.pepSocio || ""}
                  error={formErrors.pepSocio}
                  errorMessage={formErrors.pepSocio}
                  onChange={handleChange}
                  options={[
                    { id: 1, name: "Não" },
                    { id: 2, name: "Sim" },
                  ]}
                />
              </div>
              <div className="col-span-6 sm:col-span-3">
                <Input
                  label={"Início PPE"}
                  type={"text"}
                  name={"dataInicioPEPSocio"}
                  id={"dataInicioPEPSocio"}
                  value={dateMask(formData.dataInicioPEPSocio) || ""}
                  disabled={!formData.pepSocio || formData.pepSocio === "1"}
                  error={formErrors.dataInicioPEPSocio}
                  errorMessage={formErrors.dataInicioPEPSocio}
                  onChange={handleChange}
                  placeholder={"dd/mm/aaaa"}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="shadow overflow-hidden sm:rounded-md">
          <div className="px-6 py-4 sm:py-5 bg-white border-b border-gray-200">
            <h3 className="text-lg font-medium leading-6 text-gray-900">
              Endereço do sócio
            </h3>
          </div>
          <div className="px-4 py-7 bg-white sm:p-7">
            <div className="grid grid-cols-6 gap-7">
              <div className="col-span-6 sm:col-span-2 lg:col-span-3">
                <Input
                  label={"CEP"}
                  type={"text"}
                  name={"enderecoCEPSocio"}
                  id={"enderecoCEPSocio"}
                  value={cepMask(formData.enderecoCEPSocio) || ""}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={formErrors.enderecoCEPSocio}
                  errorMessage={formErrors.enderecoCEPSocio}
                />
              </div>
              <div className="col-span-6">
                <Input
                  label={"Rua"}
                  type={"text"}
                  name={"enderecoSocio"}
                  id={"enderecoSocio"}
                  value={formData.enderecoSocio || ""}
                  onChange={handleChange}
                  error={formErrors.enderecoSocio}
                  errorMessage={formErrors.enderecoSocio}
                />
              </div>
              <div className="col-span-6 sm:col-span-2">
                <Input
                  label={"Número"}
                  type={"text"}
                  name={"enderecoNumeroSocio"}
                  id={"enderecoNumeroSocio"}
                  value={formData.enderecoNumeroSocio || ""}
                  onChange={handleChange}
                  error={formErrors.enderecoNumeroSocio}
                  errorMessage={formErrors.enderecoNumeroSocio}
                />
              </div>
              <div className="col-span-6 sm:col-span-2">
                <Input
                  label={"Bairro"}
                  type={"text"}
                  name={"enderecoBairroSocio"}
                  id={"enderecoBairroSocio"}
                  value={formData.enderecoBairroSocio || ""}
                  onChange={handleChange}
                  error={formErrors.enderecoBairroSocio}
                  errorMessage={formErrors.enderecoBairroSocio}
                />
              </div>
              <div className="col-span-6 sm:col-span-2">
                <Input
                  label={"Complemento"}
                  type={"text"}
                  name={"enderecoComplementoSocio"}
                  id={"enderecoComplementoSocio"}
                  value={formData.enderecoComplementoSocio || ""}
                  onChange={handleChange}
                />
              </div>
              <div className="col-span-6 sm:col-span-3">
                <Select
                  label={"Estado"}
                  id={"enderecoEstadoSocio"}
                  name={"enderecoEstadoSocio"}
                  value={formData.enderecoEstadoSocio || ""}
                  onChange={handleChange}
                  placeholder={"Selecione o Estado"}
                  options={ufs}
                  error={formErrors.enderecoEstadoSocio}
                  errorMessage={formErrors.enderecoEstadoSocio}
                />
              </div>
              <div className="col-span-6 sm:col-span-3">
                <Input
                  label={"Cidade"}
                  type={"text"}
                  name={"enderecoCidadeSocio"}
                  id={"enderecoCidadeSocio"}
                  value={formData.enderecoCidadeSocio || ""}
                  onChange={handleChange}
                  error={formErrors.enderecoCidadeSocio}
                  errorMessage={formErrors.enderecoCidadeSocio}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="shadow overflow-hidden sm:rounded-md">
          <div className="px-6 py-4 sm:py-5 bg-white border-b border-gray-200">
            <h3 className="text-lg font-medium leading-6 text-gray-900">
              Validações
            </h3>
          </div>
          <div className="px-4 py-7 bg-white sm:p-7">
            <div className="grid grid-cols-6 gap-7">
              <div className="col-span-6">
                <CheckBox
                  id={"solicitanteBypassSolicitacao"}
                  name={"solicitanteBypassSolicitacao"}
                  label={"Ignorar validações da IdWall, PH3A e Clearsale"}
                  description={
                    "Ao marcar essa opção, todas as estapas de validação serão ignoradas."
                  }
                  checked={formData.solicitanteBypassSolicitacao || false}
                  onChange={handleChange}
                />
              </div>
              <div className="col-span-6">
                <TextArea
                  id={"solicitanteRazaoOnboarding"}
                  name={"solicitanteRazaoOnboarding"}
                  label={"Motivo"}
                  placeholder={
                    "Descreva o motivo da abertura manual dessa conta e, caso tenha marcado a opção acima, o motivo de ignorar as validações de segurança. (max 500 caracteres)"
                  }
                  maxLength={500}
                  onChange={handleChange}
                  error={formErrors.solicitanteRazaoOnboarding}
                  errorMessage={formErrors.solicitanteRazaoOnboarding}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="flex-col flex sm:flex-row items-end sm:items-center justify-end gap-3">
          {Object.keys(formErrors).length > 0 && (
            <div className="flex items-center gap-2">
              <p className="text-sm text-red-600">
                Revise os erros no formulário e tente novamente
              </p>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-5 w-5 fill-red-600"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fillRule="evenodd"
                  d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z"
                  clipRule="evenodd"
                />
              </svg>
            </div>
          )}
          <div className="flex items-center justify-end gap-3">
            <Button type={"submit"} label={"Criar conta"} />
            <Button
              type={"button"}
              label={"Cancelar"}
              secondary
              onClick={() => navigate("/contas")}
            />
          </div>
        </div>
      </form>
    </>
  );
}
