import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { Routes, Route, Navigate } from "react-router-dom";

import Home from "features/home";
import Accounts from "features/accounts";
import AccountDetails from "features/accounts/details";
import NewAccount from "features/accounts/new";
import Cancellation from "features/accounts/cancellation";
import NotFound from "features/not-found";
import Conciliation from "features/financial/conciliation";
import BankDetails from "features/financial/conciliation/details";
import Fees from "features/financial/fees";
import PermissiveList from "features/onboarding/permissive-list";
import RestrictiveList from "features/onboarding/restrictive-list";
import Settings from "features/settings";
import OpeningList from "features/onboarding/opening-list";
import Errors from "features/errors";
import Logs from "features/logs";
import PendingTransactions from "features/financial/transactions";
import LimitsRequest from "features/accounts/limits";
import { setNotification } from "reducers/notification/notificationSlice";
import { checkRole } from "utils/isRoleAllowed";
import {
  USER_FINANCEIRO,
  USER_ADMIN,
  USER_OPERACOES,
  PRE_TRANSACTION_APROVER,
  ADMIN_ECOSYSTEM,
} from "utils/const";

const ProtectedRoute = ({
  allowedRole,
  userRole,
  Component,
  notification = true,
}) => {
  const dispatch = useDispatch();

  if (checkRole(userRole, allowedRole)) return <Component />;

  notification &&
    dispatch(
      setNotification({
        type: "error",
        title: "Acesso restrito",
        message: "Seu usuário não tem permissão para acessar essa página!",
      })
    );

  return <Navigate to="/contas" replace={true} />;
};

export default function Router() {
  const { role } = useSelector((store) => store.user);

  return (
    <Routes>
      <Route
        path="/"
        element={
          role.length > 0 && (
            <ProtectedRoute
              allowedRole={[USER_ADMIN, ADMIN_ECOSYSTEM]}
              userRole={role}
              Component={Home}
              notification={false}
            />
          )
        }
      />
      <Route path="/contas" element={<Accounts />} />
      <Route path="/contas/detalhes/:idClient" element={<AccountDetails />} />
      <Route
        path="/contas/abertura"
        element={
          role.length > 0 && (
            <ProtectedRoute
              allowedRole={[USER_OPERACOES, USER_ADMIN, ADMIN_ECOSYSTEM]}
              userRole={role}
              Component={NewAccount}
            />
          )
        }
      />
      <Route
        path="/contas/cancelamento"
        element={
          role.length > 0 && (
            <ProtectedRoute
              allowedRole={[USER_OPERACOES, USER_ADMIN, ADMIN_ECOSYSTEM]}
              userRole={role}
              Component={Cancellation}
            />
          )
        }
      />
      {/* <Route
        path="/contas/limites"
        element={
          role.length > 0 && (
            <ProtectedRoute
              allowedRole={[USER_ADMIN, ADMIN_ECOSYSTEM]}
              userRole={role}
              Component={LimitsRequest}
            />
          )
        }
      /> */}
      <Route
        path="/financeiro/conciliacao"
        element={
          role.length > 0 && (
            <ProtectedRoute
              allowedRole={[USER_FINANCEIRO, USER_ADMIN, ADMIN_ECOSYSTEM]}
              userRole={role}
              Component={Conciliation}
            />
          )
        }
      />
      <Route
        path="/financeiro/conciliacao/:idBank"
        element={
          role.length > 0 && (
            <ProtectedRoute
              allowedRole={[USER_FINANCEIRO, USER_ADMIN, ADMIN_ECOSYSTEM]}
              userRole={role}
              Component={BankDetails}
            />
          )
        }
      />
      <Route
        path="/financeiro/tarifas"
        element={
          role.length > 0 && (
            <ProtectedRoute
              allowedRole={[USER_OPERACOES, USER_ADMIN, ADMIN_ECOSYSTEM]}
              userRole={role}
              Component={Fees}
            />
          )
        }
      />
      <Route
        path="/financeiro/transacoes"
        element={
          role.length > 0 && (
            <ProtectedRoute
              allowedRole={[USER_ADMIN, PRE_TRANSACTION_APROVER]}
              userRole={role}
              Component={PendingTransactions}
            />
          )
        }
      />
      <Route path="/onboarding/permitir" element={<PermissiveList />} />
      <Route path="/onboarding/restringir" element={<RestrictiveList />} />
      {/* <Route path="/onboarding" element={<OpeningList />} /> */}
      <Route
        path="/admin/"
        element={
          role.length > 0 && (
            <ProtectedRoute
              allowedRole={[USER_ADMIN, ADMIN_ECOSYSTEM]}
              userRole={role}
              Component={Settings}
            />
          )
        }
      />
      <Route
        path="/logs/"
        element={
          role.length > 0 && (
            <ProtectedRoute
              allowedRole={[USER_ADMIN, ADMIN_ECOSYSTEM]}
              userRole={role}
              Component={Logs}
            />
          )
        }
      />
      <Route path="/erros" element={<Errors />} />
      <Route path="/nao-encontrado" element={<NotFound />} />
      <Route path="*" element={<Navigate replace to="/nao-encontrado" />} />
    </Routes>
  );
}
